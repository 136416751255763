@mixin roundedbox($round:5px,$color:#ffffff)
{
  position:relative;
  @include border-radius($round);
  color:$color;

  .content{

  }

}
@mixin textShadow(){
  text-shadow: rgba(0,0,0,.6) 0 0.1em 0.1em;
}
@mixin bs(){
  @include box-sizing('border-box');
}
@mixin boxShadow(){
  box-shadow: 6px 4px 26px 0px rgba(0,0,0,0.59);
  -webkit-box-shadow: 6px 4px 26px 0px rgba(0,0,0,0.59);
  -moz-box-shadow: 6px 4px 26px 0px rgba(0,0,0,0.59);
}
@mixin setTransition($element:all,$time:0.3s){
  -webkit-transition: $element $time ease;
  -o-transition:$element $time ease;
  transition:$element $time ease;
}

@mixin fontSize($fontsize:16){

  $param: $fontsize * 0.0625;
  $fontsize:$param+rem;
  font-size: $fontsize;

}@mixin lineHeight($lh:16){

  $param: $lh * 0.0625;
  $lineheight:$param+rem;
  line-height: $lineheight;
}

@mixin sitePaddingComplete($padding:25px) {
  padding-left: $padding;
  padding-right: $padding;
}
@mixin sitePaddingLeft($padding:25px) {
  padding-left: $padding;
}
@mixin sitePaddingRight($padding:25px){

  padding-right:$padding;
}
@mixin verticalMarginComplete($margin:77px){

  margin-top:$margin;
  margin-bottom:$margin;

}

@mixin buildFontSizes($fs:20,$lh:30){

  @include fontSize($fs);
  @include lineHeight($lh);

}

@mixin centerAbsElement(){
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
@mixin topMargin($margin:50px){

  margin-top:$margin;

}@mixin bottomMargin($margin:50px){

  margin-bottom:$margin;


}
@mixin topMargin($margin:50px){

  margin-top:$margin;

}
@mixin vMargin($margintop:50px,$marginbottom:50px) {
  margin-bottom: $marginbottom;
  margin-top: $margintop
}
@mixin overlayfigure() {
  position: relative;
  margin-bottom:25px;

}
@mixin border-radius($br:0px){
  border-radius: $br;
}
@mixin box-sizing($state:'border-box'){
  box-sizing: $state;

}
@mixin buildMenuButton(){
  position: absolute;
  top:34px;
  right:10px;
  z-index:99999999999999999999999999999999999;
  width:50px;
  height:50px;
  span {
    background-color: $menu-button-line-color;
    display: block;
    width: 40px;
    height: 5px;
    margin-bottom: 6px;
    @include border-radius(5px);

    @include setTransition();

  }
  @media screen  and (min-width:1024px) {
    display:none;

  }

}

.menu-button--active {

  span.center {
    display: none!important;


  }

  span.top {

    transform: rotate(-45deg);
    position:relative;
    top:11px;
  }span.bottom {

     transform: rotate(45deg);
   }


}
@mixin tablet-por {
  @media(min-width: 767px) {
    @content
  }
}
@mixin phone{
  @media(max-width: 766px){@content}
}


@mixin tablet-max{
  @media(min-width: 1024px){@content}
}
@mixin desk-small{
  @media(min-width: 1200px){@content}
}
@mixin desk-max{
  @media(min-width: $maxwidth){@content}
}

@mixin cta-button(){
  background-color:$red;
  color:$white;
  text-transform: uppercase;
  @include fontSize(19);
  padding:15px;
  text-decoration: none;
  font-weight: 500;
  @include tablet-por {
    white-space: nowrap;
  }
}
@mixin paddingelement{

  @include phone{
    padding:0 13px;

  }
  @include tablet-por{
    padding: 0 25px;
  }
  @include desk-small{
    padding:0 50px
  }
  @include desk-max{
    padding:0;
  }
}
