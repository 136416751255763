@import "evolve-ui-rescontent";
.formsection{
  width:100%;

}
.formwrapper{

  padding:50px;

  background-color:$formfieldBGColor;

  .textinput{

    label{
      display:block;
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom:20px;
      color:$headlinemastercolor;
      font-size:18px;
      line-height: 1;

    }
    label{
      margin-top:15px;

    }
    label:first-of-type{

    }
    textarea{
      width:100%;
      height:150px;
      overflow: hidden;
      font-family: 'Roobert-Regular', sans-serif;
      padding:5px 10px;
      @include fontSize(16);
    }
    input[type=text],select{
      font-family: 'Roobert-Regular', sans-serif;
      width:100%;
      padding:5px 10px;
      box-sizing: border-box;
      @include setTransition();
      @include fontSize(16);
    }
    input[type=checkbox]{
      padding-right:15px;
      margin-top:10px;

      margin-right:10px;
    }
    img{
      width:100%;
      height:auto;
      max-width:200px;
    }

    button{
      border:0;
      font-family: 'Roobert-Regular', sans-serif;
      color:$w;
      background-color: $headlinemastercolor;
      padding:10px;
      @include fontSize(16);
      text-transform: uppercase;
      font-weight:300;
      cursor:pointer;
      @include border-radius(5px);
      @include setTransition();
      &:hover{
        background-color: lighten($headlinemastercolor,5%);

      }
    }
  }

}
.cta-button{
  border:0;
  font-family: 'Roobert-Regular', sans-serif;
  color:$w;
  cursor:pointer;
  @include fontSize(20);
  font-weight:300;
  text-transform: uppercase;
  background-color: $headlinemastercolor;
  padding:10px;
  @include border-radius(5px);
  @include setTransition();
  &:hover{
    background-color: lighten($mioblue,5%);

  }
}
.anserwinner{
  margin:40px 0!important;
}

.addquestionform
{
  display:none;
}

.addformvisible{
  display:block;
}
.secondform{

}
.logopreview{
  img {
    cursor: pointer;
    border: 1px solid #999999;
    padding:10px;
    max-width:150px;
    height:auto;
    margin-top:20px;
  }
}
.blankimage{
  width:150px;
  cursor: pointer;
  border: 1px solid #999999;
  padding:10px;
  height:150px;
}
.deletebutton{
  color:#00B3B1;
  text-decoration: underline;
  font-weight:bold;
  cursor: pointer;
  &:hover{
    color:#ffffff;
  }
}
.loginpage{
  background-color: $mioblue;
  position:relative;

}
input[type=text]:focus,textarea:focus{
  background-color: hsl(60deg, 90%,90% / 0.92);
  border:unset;
  box-shadow: unset;



}