/*FlexSystem*/

$tablet-gap:24px;
@mixin buildImDesktopFlex($flex:1){

  $para: $flex * 8.3333333333333%;
  $out: $para;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $out;
  flex: 0 0 $out;
  max-width:  $out;

}
@mixin buildDesktopFlex($flex:1){

  $para: $flex * 8.3333333333333%;
  $out: $para;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $out;
  flex: 0 0 $out;
  max-width:  $out;

}
@mixin buildImDesktopFlex($flex:1){

  $para: $flex * 8.3333333333333%;
  $out: $para;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $out;
  flex: 0 0 $out;
  max-width:  $out;

}
@mixin buildDesktopMarginLeft($flex:1){

  $para: $flex * 8.3333333333333%;

  margin-left:$para;

}
@mixin buildDesktopInMarginLeft($flex:1){

  $para: $flex * 8.3333333333333%;

  margin-left:$para;

}
@mixin buildDesktopMarginRight($flex:1){

  $para: $flex * 8.3333333333333%;
  $flexw: $para;
  margin-right:$flexw;

}
@mixin buildInDesktopMarginRight($flex:1){

  $para: $flex * 8.3333333333333%;
  $flexw: $para;
  margin-right:$flexw;

}
@mixin buildPhoneMarginLeft($flex:1){

  $para: $flex * 25%;

  margin-left:$para;

}
@mixin buildPhoneMarginRight($flex:1){

  $para: $flex * 25%;
  $flexw: $para;
  margin-right:$flexw;

}
@mixin buildTabletGapMarginLeft($gml:1){

  $para: $tablet-gap * $gml;
  margin-left:$para;
}
@mixin buildTabletGapMarginRight($gml:1){

  $para: $tablet-gap * $gml;
  margin-right:$para;
}

@mixin buildTabletMarginLeft($flex:1){

  $para: $flex * 12.5%;

  margin-left:$para;

}
@mixin buildTabletMarginRight($flex:1){

  $para: $flex * 12.5%;
  $flexw: $para;
  margin-right:$flexw;

}


@mixin buildTabletFlex($flex:1){

  $para: $flex * 12.5%;
  $out: $para;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $out;
  flex: 0 0 $out;
  max-width:  $out;


}
@mixin buildPhoneFlex($flex:1){

  $para: $flex * 25%;
  $out: $para;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $out;
  flex: 0 0 $out;
  max-width:  $out;

}

@mixin buildFlexDirection($direct:row){

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

  -ms-flex-direction: $direct;

  flex-direction: $direct;
}
@mixin buildFlexWrap($wrap:wrap){

  -ms-flex-wrap: $wrap;

  flex-wrap: $wrap;
}
@mixin buildJustifyContent($jfc:flex-start){

  -webkit-box-pack: $jfc;

  -ms-flex-pack: $jfc;

  justify-content: $jfc;
}
@mixin buildFlexAlignItems($item:center){

  -webkit-box-align: $item;

  -ms-flex-align: $item;

  align-items: $item;
}
@mixin buildFlexAlignContent($item:flex-start){

  -ms-flex-line-pack: $item;

  align-content: $item;
}
@mixin buildFlexgAP($gap:0){

  gap: $gap;
}

$tablet-gap:21px;



/*Build the Smart Phones Flex Sizes*/
/*mbs - mobileboxsize*/

/*Tablet Sizes and Margins*/

.flexrow{
  img{
    max-width:100%;
    height:auto;
  }
}

@media screen and (min-width:767px) {
  .flexrow{
    display:flex;
    justify-content: flex-start;
    flex-direction: column;flex-wrap: wrap;
    margin: 0;

  }
  .flexrow--full{
    gap:unset!important;
  }

  .flexrow{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: horizontal;

    -webkit-box-direction: normal;

    -ms-flex-direction: row;

    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap:24px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
  }


  .tab-flex-1{

    @include buildTabletFlex(1);

  }.tab-flex-2{

       width: calc(1/4*100% - (1 - 1/4)*24px);

   }.tab-flex-3{

           width: calc(1/3*100% - (1 - 1/3)*24px);

    }.tab-flex-4{

                width: calc(1/2*100% - (1 - 1/2)*24px);
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(1/2*100% - (1 - 1/2)*24px);
                flex: 0 0 calc(1/2*100% - (1 - 1/2)*24px);
                max-width:calc(1/2*100% - (1 - 1/2)*24px);

     }.tab-flex-5{

        @include buildTabletFlex(5);

      }.tab-flex-6{

         @include buildTabletFlex(6);

       }.tab-flex-7{

          @include buildTabletFlex(7);

        }.tab-flex-8{

           @include buildTabletFlex(8);

         }
  /*Build Margin on Gap-Size

  -ml == margin-left
  -mr == margin-right
  /
   */


  .tablett-gap-ml-1{
    @include buildTabletGapMarginLeft(1);
  }
  .tablett-gap-ml-2{
    @include buildTabletGapMarginLeft(2);
  }
  .tablett-gap-mr-1{
    @include buildTabletGapMarginRight(1);
  } .tablett-gap-mr-2{
      @include buildTabletGapMarginRight(2);
    }

}
@media screen and (min-width:1024px) {

  .flexrow{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: horizontal;

    -webkit-box-direction: normal;

    -ms-flex-direction: row;

    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap:15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
  }




  .flex-1{
    @include buildDesktopFlex(1);
  }
  .flex-2{
    @include buildDesktopFlex(2);
  }
  .flex-3{
    width: calc(25% - (1 - 1/4)*15px);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - (1 - 1/4)*15px);
    flex: 0 0 calc(25% - (1 - 1/4)*15px);
    max-width:calc(25% - (1 - 1/4)*15px);
  }
  .flex-4{
    width: calc(1/3*100% - (1 - 1/3)*15px);
    -ms-flex: 0 0 calc(1/3*100% - (1 - 1/3)*15px);;
    flex: 0 0 calc(1/3*100% - (1 - 1/3)*15px);
    max-width:calc(1/3*100% - (1 - 1/3)*15px);

  }
  .flex-5{
    width: calc(41.6667% - (1 - 1/2)*15px);

    -ms-flex: 0 0 calc(41.6667% - (1 - 1/3)*15px);
    flex: 0 0 calc(41.6667% - (1 - 1/3)*15px);
    max-width:calc(41.6667% - (1 - 1/3)*15px);

  }
  .flex-6{
    width: calc(1/2*100% - (1 - 1/2)*15px);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(1/2*100% - (1 - 1/2)*15px);
    flex: 0 0 calc(1/2*100% - (1 - 1/2)*15px);
    max-width:calc(1/2*100% - (1 - 1/2)*15px);
    img{
      width:100% !important;
      height:auto;
    }
  } .flex-6-nogap{
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;;
    flex: 0 0 50%;;
    max-width:50%;;
    img{
      width:100% !important;
      height:auto;
    }
  }
  .flex-7{

    width: calc(58.3333% - (1 - 1/2)*15px);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(58.3333% - (1 - 1/2)*15px);
    flex: 0 0 calc(58.3333% - (1 - 1/2)*15px);
    max-width:calc(58.3333% - (1 - 1/2)*15px);
  }
  .flex-8{
    @include buildDesktopFlex(8);
  }
  .flex-9{
    @include buildDesktopFlex(9);
  }
  .flex-10{
    @include buildDesktopFlex(10);
  }
  .flex-11{
    @include buildDesktopFlex(11);
  }
  .flex-12{
    @include buildDesktopFlex(12);
  }
  .ml-1
  {
    @include buildDesktopMarginLeft(1);
  }
  .ml-2
  {
    @include buildDesktopMarginLeft(2);
  }
  .ml-3
  {
    @include buildDesktopMarginLeft(3);
  }
  .ml-4
  {
    @include buildDesktopMarginLeft(4);
  }

  .mr-1
  {
    @include buildDesktopMarginRight(1);
  }
  .mr-2
  {
    @include buildDesktopMarginRight(2);
  }
  .mr-3
  {
    @include buildDesktopMarginRight(3);
  }
  .mr-6
  {
    @include buildDesktopMarginRight(5);
  }




}
@media screen and (min-width:1171px){

  .flexrow {
    margin: 0;
  }
}
@mixin flexHalfsize(){

  @media screen and (min-width:767px){

    width: calc(1/2*100% - (1 - 1/2)*24px);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(1/2*100% - (1 - 1/2)*24px);
    flex: 0 0 calc(1/2*100% - (1 - 1/2)*24px);
    max-width:calc(1/2*100% - (1 - 1/2)*24px);
  } @media screen and (min-width:1024px){

  width: calc(1/2*100% - (1 - 1/2)*15px);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(1/2*100% - (1 - 1/2)*15px);
  flex: 0 0 calc(1/2*100% - (1 - 1/2)*15px);
  max-width:calc(1/2*100% - (1 - 1/2)*15px);  }
}
@mixin flexHalfsizeNoGap(){

  @media screen and (min-width:767px){

    width: calc(1/2*100% - (1 - 1/2)*0px);
  } @media screen and (min-width:1024px){

  width: calc(1/2*100% - (1 - 1/2)*0px);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(1/2*100% - (1 - 1/2)*0px);
  flex: 0 0 calc(1/2*100% - (1 - 1/2)*0px);
  max-width:calc(1/2*100% - (1 - 1/2)*0px);  }
}