@import "../evolve-ui-rescontent";

.backendmasterframe{

  max-width: 1200px;
  margin-left:600px;
@include setTransition();
  padding-top:200px;
h1{
  margin-bottom:20px;
}
}

.backendcollapsed{
  margin-left:100px;
  max-width:1600px;

  .inlay{

  }
}