$infobg: #A5DFD3;


.info{

  display:block;
background-color: $infobg;
  border-radius:5px;
  max-width:400px;
  padding:10px;text-align: center;
font-weight:bold;
  margin:25px 0 50px 0;
}
.filuploadinput{
  display:none;
}

