.yellowbackground {
  background-color: yellow;
  color: #000000; }

.boldfont {
  font-weight: bold; }


.deletebutton{
  color:#00B3B1;
  text-decoration: underline;
  font-weight:bold;
}