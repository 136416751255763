
.mio-ui-logo{
padding:50px;
  max-width:300px;
  margin:0 auto;
  img{
    width:100%;
    height:auto;
  }
}

.mio-ui-content {
  margin-left: 55px;

  @media screen and (min-width: 1024px) {
    margin-left: 550px;
    padding: 160px 20px;
    max-width: 1200px;

  }

}