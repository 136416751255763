
.buttonmenu{
  position:absolute;
  right:-0px;
  top:29px;
}
.editorframe{
  position: relative;
  margin-top:50px;
label{

}
p.viewstate{
border:1px solid #cccccc;
padding:10px;
  margin-bottom:25px;
  font-weight:bold;
  max-width:100px;
  text-align: center;
}
}
.button{
  border-radius: 0!important;
  background-color: #A5DFD3!important;
  color:#024948!important;
}
.htmlpreview {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 0px;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  margin-block-end: 31px;
  border: 1px solid #000000;

  h1, h2, h3 {
    padding: 0;
    margin: 0;
    position: static;
    font-size: 18px;


  }
}
  .txtHTML{
    width:100% !important;
    height:300px!important;
    overflow:unset!important;
    box-sizing: border-box;
    border:1px solid #000000;
    margin-block-end: 25px;
h1,h2,h3{
  font-size:18px;
  font-weight: bold;
}
  }

.buttonactive{
  background-color: #024948!important;
}
.mediabuttons{
  position:relative;
  top:80px;
  left:89%;

  ul{
    list-style-type: none;
    display:flex;
    margin:0;
    padding:0;
    li{
      padding-right:10px;
    }
    span{
      display:block;
      cursor: pointer;



    }
    span.addimage{
      background-image: url("../../assets/addimage.svg");
      width:36px;
      height:36px;
      background-repeat: no-repeat;
    } span.addvideo{
      background-image: url("../../assets/addvideo.svg");
      width:36px;
      height:36px;
      background-repeat: no-repeat;
    }
  }
}
.nomargin{
  margin-block-end: 0!important;
}