@import "../evolve-ui-rescontent";
.tabnavframe{
  width:100%;
height:60px;

}

.tabnavlist{
list-style-type: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
display:flex;
  justify-content: flex-start;

  li.tabnavlistitem{
  height:inherit;
    width: fit-content;text-align: center;
  padding:10px 20px;
    border-radius: 8px;
    cursor:pointer;
    color:$w;
    margin-inline-end: 15px;
    background-color: $headlinemastercolor;
@include setTransition();
    &:hover{
      background-color: $mingreen;
      color:$headlinemastercolor;
    }
  }
}
.active{
  background-color: $mingreen!important;
  color:$headlinemastercolor!important;
}
.formarea{
  @include setTransition();
  border:1px dotted $headlinemastercolor;
  padding:25px 20px;
  h2{
    margin-top:0;
  }
}
.formitem{
  border:1px solid $mingreen;
  border-radius:8px;
  height:410px;
position:relative;
  flex-wrap: wrap;
  padding:0 20px 10px 20px;

  margin-block-end: 25px;
  h4{
height:50px;

    margin-inline-start: -20px;
    margin-inline-end: -20px;
    margin-block-end: 20px ;
    text-transform: uppercase;
    @include fontSize(18);
    font-weight: bold;

    padding:10px 20px;
    background-color: $mingreen;
  }
  form{
    width:100%;
  }
  input{
  border-radius: 8px;
    border-color:gray;
    border:1px solid gray;
  width:100%;
    height:50px;
    padding:10px;
    outline: unset;
    margin-top:15px;
    margin-bottom:15px;
  }
textarea{
  border-radius: 8px;
  width:100%;
  height:150px;
  padding:10px;
  margin-top:15px;
  border-color:gray;
  border:1px solid gray;
  margin-bottom:15px;
  outline: unset;
}
  input:focus,textarea:focus{
    background-color: hsl(60deg, 90%,90% / 0.92);
    border:1px solid inherit;

  }


}

.button{
  border:0;
  font-family: 'Roobert-Regular', sans-serif;
  color:$w;
position: absolute;
  bottom:20px;
  right:20px;
  cursor:pointer;
  @include fontSize(20);
  font-weight:300;
  text-transform: uppercase;
  background-color: $headlinemastercolor;
  padding:10px;
  @include border-radius(5px);
  @include setTransition();
  &:hover{
    background-color: lighten($mioblue,5%);
    color:$headlinemastercolor;
  }
}