$logbg:#efefef;
@import "../evolve-framework/evolve-ui-mixins";
@import "../evolve-framework/evolve-ui-colors";

.loginformwrapper{

  background-color: $logbg ;
  width:355px;
  height:500px;
  margin:100px auto;
  box-sizing: border-box;
  padding:25px 20px;
border-radius: 10px;
  border:1px solid #dddddd;



}

.loginimage{
text-align: center;
  width:100%;
  margin-bottom:30px;
  img{

  }
}

.form{


  .field{
margin-bottom:5px;
    label {
      @include fontSize(18);
      width: 140px;
      margin:30px 0 5px 0;
      display: inline-block;
    }
      input{
        padding:10px;
        width:100%;
        max-width:290px;


    }
  }
}


.submitbutton{
  button{

display: block;
    margin:30px auto;
    border:0;
    font-family: 'Roobert-Regular', sans-serif;
    color:$w;
    cursor:pointer;
    @include fontSize(18);
    font-weight:300;
    text-transform: uppercase;
    background-color: #024948;
    padding:10px 20px;
   width:250px;
    @include border-radius(5px);
    @include setTransition();
    &:hover{
      background-color: lighten(#024948,5%);

    }
  }
}


.staterorrs
{
  background-color: darkred;
padding:10px;
  border-radius:5px;
  p{color:$w;}
}
.state
{
p{
  text-align: center;
}
}
