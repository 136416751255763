.mioui-section-list{

  h1{
    margin-bottom:25px;
  }
  table{
    border:2px solid $mioblue;
    border-radius: 5px;
    width:100%;
    max-width:800px;
    th {
      font-weight: bold;
      text-align: left;
      padding:15px 5px;
    }

    td{
      padding:5px;

      a{
        color:$headlinemastercolor;
        font-weight: bold;
      }
    }
    tr{
      margin-bottom:5px;
      background-color:#dddddd;
      @include setTransition();
      &:hover{
        background-color: $mioblue!important;
        color:$headlinemastercolor;
        a{
          color:$headlinemastercolor;
        }
      }
    }
    tr:nth-child(odd){
      background-color: #efefef;
    }
    a{

    }
  }

}

.button-fi-control,.Logouploader button{
  background-color: #333333!important;

}
.button-fi-control{
  margin-top:65px;
}
.Logouploader{
  span{
    display:block;
    margin-top:10px;
  }
}
.statesave {
  max-width: $maxwidth;
  margin-left: 750px;
  padding: 55px 0;
}
.hometeaser{
  max-width:670px;
  margin-left:auto;
  margin-right:auto;
  position:relative;
  left:-270px;

h1{
  margin-bottom: 30px!important;
}

}
.hometeaser-second{
  background-color: $mingreen;
  @include box-sizing();
  margin-top:45px;
  padding:50px;
  border-radius:10px;
  a{
    color:$headlinemastercolor;
  }
  p:first-of-type{
    padding-block-end: 25px;
  }

}
.mio-page-content{
  h1{
    margin-left:550px;
    position:relative;
    top:160px;
  }
}
