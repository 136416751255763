.imageframe{
margin:25px 0 40px 0;

}

.borderlist{
  border:2px solid red;
}



.pagethumbs{

  display:flex;
  justify-content: flex-start;
  list-style-type: none;
  margin:30px 0 20px 0;
  padding:0;
li {
  width: 15%;
  margin-right: 1%;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    max-height:115px;
    overflow: hidden;

  }

  span {
    width: 36px;
    height: 36px;
    display: inline-block;
    color: transparent;
    font-size: 16px;
background-image: url("../assets/close.svg");
    background-repeat: no-repeat;
    border-radius: 50%;

    font-weight: bold;
    box-sizing: border-box;
    transition: all 0.5s;

    position: absolute;
    top: -10px;
    right: -12px;
    z-index: 99;
    opacity: 0;
cursor: pointer;
  }

  &:hover {
    span {

      opacity: 1 !important;
    }

  }

}
}
.contentsingleimage{

  img{
    position:relative;
  }
}
.singleimage{

}