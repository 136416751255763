/* noto-sans-display-100 - latin */
@font-face {
  font-family: 'Roobert-Regular';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/Roobert-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Roobert-Regular';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Roobert-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Roobert-Bold';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Roobert-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Roobert-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/Roobert-Bold.woff') format('woff');
}
