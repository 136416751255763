@import "../evolve-ui-colors";
@import "../evolve-ui-mixins";
@import "../base";
.mobutton{

  cursor: pointer;
  position:absolute;
  top:94px;
  right:20px;
}
.evolveuinavigation ul > li {
  margin-block-start: 20px;
  margin-block-end: 10px;
}
.evolveuinavigation ul li ul > li{
  margin-top:0px;
}

.evolveuinavigation {
  @include setTransition();
  width: $mio-nav-section-width;
  background-color: #F6F6F6;
  height: 100vh;
  max-width: 515px;
  position: fixed;
  left: 0;
  z-index: 9999;

  ul {
    list-style-type: none;
    margin: 0;


   padding-left:108px;
padding-right:107px;

    li {
      border-bottom: 2px solid #E3E5E3;
      padding-bottom: 20px;

      a {
        text-decoration: none;
        color: #000000;
        font-weight: 700;


        @include setTransition();

        &:hover {
          color: #075B59;

        }
      }
    }

    ul {
      padding: 0px 0px;

      li {
        margin-bottom: 0px;
        border-bottom: 0;
        padding-bottom: 0;

        a {
          color: #000000;
          font-weight: 300;
          text-transform: unset ;
        }
      }
    }
  }

  li:last-of-type {
    border-bottom: 0;
  }


  li.pagewithoutlink {
    text-decoration: none;
    color: #000000;
    font-weight: 700;

    border-bottom: 2px solid #E3E5E3;
  }
}
.naviscollpapsed{

  left:-86%;
  @media screen and (min-width: 545px){
    left:-467px!important;
  }
}
.logout{
  text-transform: uppercase;
  @include fontSize(12);
  position:absolute;
  right:0px;
  top:10px;
}
.activelink{
  color:#075B59!important;

}
.evolvelogo{
  width: inherit;
  text-align: center;
  padding-top:60px;
  padding-bottom:60px;
  padding-left:108px;
  padding-right:107px;

}
.noborder{
  border-bottom:0!important;

}

.settings{
  position:relative;
  top:30px;
margin-block-start: 105px;
}
.lastitems{
position:relative;
  top:0px;

}
.scrollist{
 height:600px!important;
padding-bottom:200px;
  overflow-y:scroll;

  .lastitems{
  }
  .settings{
    margin-block-start: 0;
  }
}
.scrolllist::-webkit-scrollbar,
.scrolllist::-webkit-scrollbar-track {

  background-color:transparent!important;
}