.addimage{
cursor: pointer;
  display:block;
  background-image: url("../assets/addimage.svg");
  width:36px;
  height:36px;
  background-repeat: no-repeat;
  position:absolute;top:-95px!important;
right:-50px;
}
.secondform{
  position:relative;

}