$cta-color:#A5DFD3;
$mioblue: $cta-color;
$boldFont: "Roobert-Bold";
$nomrFont: "Roobert-Regular";
$mingreen: #A5DFD3;
$miogreen: $mingreen;
$mio-nav-section-width:100%;
$headlinemastercolor: #024948;
$maxwidth:1024px;

.inlay{

}

body{
  font-family: "Roobert-Regular",sans-serif;
  font-size:100%;
}

h1{
  @include fontSize(35);
  @include lineHeight(50);
  font-weight: 500;
  letter-spacing: 0px;
  margin:0 0 50px 0;
  font-family: $boldFont;
  color:$headlinemastercolor;
}
h2{
  @include fontSize(30);
  @include lineHeight(40);
  font-weight: 500;
  font-family: $boldFont;
  letter-spacing: 1px;
  margin-bottom:25px;
  color:$headlinemastercolor;
}
h3{
  @include fontSize(20);
  @include lineHeight(30);
  font-weight: 500;
  margin-bottom:25px;
  font-family: $boldFont;
  letter-spacing: 0px;
  color:$headlinemastercolor;
}
h4 {
  @include fontSize(24);
  @include lineHeight(30);
  font-weight: 500;
  letter-spacing: 0px;
  color:$headlinemastercolor;


}
h5{
  @include fontSize(20);
  @include lineHeight(30);
  font-weight: 700;
  color:$headlinemastercolor;
  letter-spacing: 0px;


}
h6{
  @include fontSize(18);
  @include lineHeight(30);
  font-weight: 700;
  letter-spacing: 0px;
  color:$headlinemastercolor;
}
p{
  @include fontSize(16);
  @include lineHeight(25);
color:#23282D;
}

.has-master-color{
  color: $headlinemastercolor;
}
.texttraupper{
  text-transform: uppercase;
}
.textfwb{
  font-weight:bold;
}
.txtalcenter{
  text-align: center;
}
.txtalright{
  text-align: right;
}

ul{


  li{
    @include fontSize(18);
    @include lineHeight(30);

  }
}
#root{
  width:100%;
  overflow-x: hidden;
}