.answeritems{
  display:none;

}
.ansbuttonlist{
  .editbutton{
    right:185px;
  }
.deletebutton{
  right:-15px;
}
  .posup {
    right:145px;
  }
}
.answerinput{
margin-bottom:20px;


  .checkboxarea{
    margin-top:10px;
  }
  input[type=text] {
    width: 100%;
    display:block;
  max-width:300px;
  }
  .listupdatebutton{
position:relative;
    left:50px;
    padding:5px 10px 0 8px;
    height: 40px;
    width:40px;
    box-sizing: border-box;
    top:-0px;
  }
  }
.aivisible{
  display: block!important;

}
.editbutton{
color:transparent;
  background-image: url("../assets/edit.svg");
  background-repeat: no-repeat;
  height:30px;
  cursor: pointer;
  font-size:12px;
  text-align: right;
position:absolute;right:200px;
  top:2px;
}
.deletebutton{
  color:transparent;
  background-image: url("../assets/delete.svg");
  background-repeat: no-repeat;
  height:30px;
  cursor: pointer;
  font-size:12px;
  text-align: right;

position:absolute;right:0px;
}
.posup{
  color:transparent;
  background-image: url("../assets/up.svg");
  background-repeat: no-repeat;
  height:30px;
  cursor: pointer;
  font-size:12px;
  text-align: right;
  top:8px;
position:absolute;right:160px;
}
.posdown{
  color:transparent;
  background-image: url("../assets/down.svg");
  background-repeat: no-repeat;
  height:30px;
  font-size:12px;
  text-align: right;
position:absolute;right:80px;
}
.buttonlist
{
  position: relative;
  width:100%;
  top:-60px;
span{
  cursor: pointer;
}
}.ansbuttonlist
{
  position: relative;
  width:100%;
  top:-30px;
   span{
     cursor: pointer;
   }
}
.questionheadline{
  cursor: pointer;
}
.listfaitems{
list-style-type: none;
margin:0;
  padding:5px;
li:nth-child(even){

}
  li{
    border-bottom:1px solid #dadada;
    margin:5px 0;
    padding:0 10px;
    font-size:14px;
  }


}
.answerinput{

 input {
   padding: 10px;
   margin: 10px;
 }
display:none;
}
.listupdatebutton{
  width:30px;
  height:30px;
text-align: center;
  border-radius: 5px;
  cursor: pointer;
  color:#ffffff;
  background-color: #00B3B1;
display: inline-block;
padding-top:7px;
padding-right:4px;
}
.answeraddform{
  position: relative;
  max-width:500px;
  display:none;
  input[type=text]{
display:block;
    margin-bottom:15px;
    width:100%;
  }
  button{
    position:absolute;
    right:0px;
  }
}
.afvisible{
  display:block!important;
}
.noposup{
  display:none!important;
}
.answeritems{

  p{
    font-weight:bold;
    text-transform: uppercase;
border-bottom: 2px solid #dddddd;
    width:100%;
    height:60px;
  }
  position: relative;
}
.addanswerbutton{
background-color: #A5DFD3;
  border:0;
  text-transform: uppercase;
  padding:15px;
  border-radius:10px;
position:absolute;
  right:0px;
  top:0;

}
.statebreakablecheck{
position:relative;
  top:2px;
}
.questioneditform{
  height:auto;

box-sizing: border-box;
  z-index:999;

  box-sizing: border-box;
.listupdatebutton{
  padding-top:10px!important;
  box-sizing: border-box;
  top:-30px;
  left:281px;

  border-radius: 0 5px 5px 0px;

}
  input{
    height:19px;
     }
}
.inquestion{
  position:relative;
  top:20px;
  left:-10px;
  border-radius: 0 5px 5px 0px;

}
.frmdatalist{
  margin-top:25px;
  h5{
    margin:15px 0;
  }
}